import { groupBy, mapValues } from 'lodash'

export const API_ROOT = '/api'
export const PROD_SERVER_URL = 'http://ec2-54-144-45-173.compute-1.amazonaws.com'

export const ROUTES = {
  HOME: '/',
  QUESTIONS: '/prompts',
  THOUGHTS_VIZ: '/responses',
  FUTURE_VIZ: '/themes',
  VOICE_VIZ: '/voices',
  NYC_MAP_VIZ: '/nyc-map',
  OG: '/answer/:answerId',
}

export const QUERYSTRING_KEYS = {
  QUESTION_ID: 'questionId',
}

export const THEMES = [
  {
    id: 1,
    label: 'Evolving Public Realm',
    color: {
      fullTint: '#10769b',
      halfTint: '#88bbcd',
    },
  },
  {
    id: 2,
    label: 'Climate Change and Resilience',
    color: {
      fullTint: '#d4543f',
      halfTint: '#eaaa9f',
    },
  },
  {
    id: 3,
    label: 'Right to Housing',
    color: {
      fullTint: '#902b62',
      halfTint: '#c895b1',
    },
  },
  {
    id: 4,
    label: 'Public Health',
    color: {
      fullTint: '#a1cad6',
      halfTint: '#d0e4eb',
    },
  },
]

export const themeMapByKey = (key) => mapValues(groupBy(THEMES, key), (t) => t[0])
export const THEME_MAP = themeMapByKey('id')
export const THEME_MAP_BY_LABEL = themeMapByKey('label')

const BOROUGHS = {
  Bronx: [
    10453,
    10457,
    10460,
    10458,
    10467,
    10468,
    10451,
    10452,
    10456,
    10454,
    10455,
    10459,
    10474,
    10463,
    10471,
    10466,
    10469,
    10470,
    10475,
    10461,
    10462,
    10464,
    10465,
    10472,
    10473,
  ],
  Brooklyn: [
    11212,
    11213,
    11216,
    11233,
    11238,
    11209,
    11214,
    11228,
    11204,
    11218,
    11219,
    11230,
    11234,
    11236,
    11239,
    11223,
    11224,
    11229,
    11235,
    11201,
    11205,
    11215,
    11217,
    11231,
    11203,
    11210,
    11225,
    11226,
    11207,
    11208,
    11211,
    11222,
    11220,
    11232,
    11206,
    11221,
    11237,
  ],
  Manhattan: [
    10026,
    10027,
    10030,
    10037,
    10039,
    10001,
    10011,
    10018,
    10019,
    10020,
    10036,
    10029,
    10035,
    10010,
    10016,
    10017,
    10022,
    10012,
    10013,
    10014,
    10004,
    10005,
    10006,
    10007,
    10038,
    10280,
    10002,
    10003,
    10009,
    10021,
    10028,
    10044,
    10065,
    10075,
    10128,
    10023,
    10024,
    10025,
    10031,
    10032,
    10033,
    10034,
    10040,
  ],
  Queens: [
    11361,
    11362,
    11363,
    11364,
    11354,
    11355,
    11356,
    11357,
    11358,
    11359,
    11360,
    11365,
    11366,
    11367,
    11412,
    11423,
    11432,
    11433,
    11434,
    11435,
    11436,
    11101,
    11102,
    11103,
    11104,
    11105,
    11106,
    11374,
    11375,
    11379,
    11385,
    11691,
    11692,
    11693,
    11694,
    11695,
    11697,
    11004,
    11005,
    11411,
    11413,
    11422,
    11426,
    11427,
    11428,
    11429,
    11414,
    11415,
    11416,
    11417,
    11418,
    11419,
    11420,
    11421,
    11368,
    11369,
    11370,
    11372,
    11373,
    11377,
    11378,
  ],
  'Staten Island': [
    10302,
    10303,
    10310,
    10306,
    10307,
    10308,
    10309,
    10312,
    10301,
    10304,
    10305,
    10314,
  ],
}

export const BOROUGHS_MAP = Object.keys(BOROUGHS).reduce((acc, key) => {
  const zipsAsObj = BOROUGHS[key].map((v) => ({ [v]: key }))
  return Object.assign(acc, ...zipsAsObj)
}, {})

export const CELL_SIDE = 20 // 10
export const CELL_PADDING = 8 // 4
export const CELL_WIDTH = CELL_PADDING + CELL_SIDE
export const EMPTY_CELL_BORDER_COLOR = '#c6bdb6'

export const LABELS_MIN_PADDING = 2
export const LABELS_FONT = "bold 20px 'Graphik-Medium'"

// This is to keep the website working on Netlify while we don't have an API plugged in
export const DEBUG = { MOCK_API_BEHAVIOR: false }
