import React from 'react'
import styles from './Footer.module.css'
import aiaPng from '../../assets/01_AIANewYork_Logo_Black.png'
import cfaPng from '../../assets/02_CenterForArch_Logo_Black.png'
import cddlPng from '../../assets/03_CDDL_Logo_Black.png'
import mitSapPng from '../../assets/04_MIT_SAP_Logo_Black.png'

const promoters = [
  {
    link: { href: 'https://www.aiany.org/', title: 'Go to: AIA New York website' },
    img: { src: aiaPng, alt: 'AIA logo' },
  },
  {
    link: {
      href: 'https://www.centerforarchitecture.org/',
      title: 'Go to: Center for Architecture website',
    },
    img: { src: cfaPng, alt: 'Center for Architecture logo' },
  },
  {
    link: { href: 'http://civicdatadesignlab.mit.edu/', title: 'Go to: CDDL website' },
    img: { src: cddlPng, alt: 'CDDL logo' },
  },
  {
    link: { href: 'https://sap.mit.edu/', title: 'Go to: MIT SAP website' },
    img: { src: mitSapPng, alt: 'MIT SAP logo' },
  },
]

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.promoters}>
        {promoters.map(({ link, img }, i) => {
          return (
            <a
              key={i}
              className={styles.logo}
              href={link.href}
              target="_blank"
              rel="noreferrer noopener"
              title={link.title}
            >
              <img src={img.src} alt={img.alt} />
            </a>
          )
        })}
      </div>
      <div className={styles.legal}>
        AIA New York | Center for Architecture 2020 ©.{' '}
        <a
          className={styles.legalLink}
          href="https://www.aiany.org/privacy-policy/"
          title="Read AIA New York Privacy Policy"
          target="_blank"
          rel="noreferrer noopener"
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  )
}
