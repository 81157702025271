import React from 'react'
import { useHistory } from 'react-router-dom'
import { stringify } from '../lib/url-utils'
import styles from './Questions.module.css'
import { parse } from 'query-string'
import { QUERYSTRING_KEYS, THEME_MAP } from '../lib/constants'
import { useRecoilValue } from 'recoil'
import { questionsAvailableIdsState, randomQuestionsState } from '../state'

export const Questions = () => {
  const history = useHistory()
  const questionsAvailableIds = useRecoilValue(questionsAvailableIdsState)
  const randomQuestions = useRecoilValue(randomQuestionsState)

  const setCurrentQuestion = (questionId) => (e) => {
    e.preventDefault()

    const { pathname, search: oldSearch } = history.location
    const parsedOldSearch = parse(oldSearch)
    const stringifiedNewSearch = stringify({
      ...parsedOldSearch,
      [QUERYSTRING_KEYS.QUESTION_ID]: questionId,
    })

    history.push({ pathname, search: stringifiedNewSearch })
  }

  return (
    <div className={styles.gridWrapper}>
      <div className={styles.grid}>
        <div className={`${styles.gridItem} ${styles.gridItemDouble}`}>
          <span className={styles.intro}>
            We invite you to share your vision for New York City and be a part of Visualize NYC
            2021. 16 prompts and questions from experts in the design, policy, and environmental
            communities consider how to envision a better New York City for all.
          </span>
        </div>

        {randomQuestions.map(({ id, themeId, prompt, author }, i) => {
          const { fullTint, halfTint } = !themeId ? 'transparent' : THEME_MAP[themeId].color
          const btnClassName = `${styles.btn} ${!themeId ? styles.btnIsEmpty : ''}`
          return (
            <div className={styles.gridItem} key={i}>
              <button
                disabled={!questionsAvailableIds.includes(id)}
                className={btnClassName}
                style={{ backgroundColor: fullTint }}
                onClick={setCurrentQuestion(id)}
                aria-label={`Click to open prompt to answer question: ${prompt}`}
              />
              <div className={styles.text}>
                <span>{prompt}...</span>
                <span style={{ color: halfTint }}>{author}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
