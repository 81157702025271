import React, { Suspense } from 'react'
import { useMedia } from 'react-media'
import { Desktop } from './views/Desktop'
import { Mobile } from './views/Mobile'
import { FullScreenMessage } from './components/Shared/FullScreenMessage'

export const App = () => {
  const isMobile = useMedia({ query: '(max-width: 768px)' })

  // useEffect(() => {
  // const body = {
  //   question: 'On NYC streets I would like to see more',
  //   response: 'green areas',
  //   zip_code: '10453',
  //   theme: 'Evolving Public Realm',
  // }

  // fetch(`api/post-response`, {
  //   method: 'POST',
  //   body: JSON.stringify(body),
  //   mode: 'cors',
  //   headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
  // })

  // fetch('/api/response?threshold=638&groupby=theme')
  //   .then((resp) => resp.json())
  //   .then((resp) => {
  //     console.log(resp)
  //   })
  // }, [])

  return (
    <Suspense fallback={<FullScreenMessage message={'Loading...'} />}>
      {isMobile ? <Mobile /> : <Desktop />}
    </Suspense>
  )
}
