import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import twitterDefaultImgUrl from '../../assets/meta_twitter.jpg'
import ogDefaultImgUrl from '../../assets/meta_og.jpg'
import { getOgImage } from '../../lib/data-utils'
import { compact } from 'lodash'

export const Meta = () => {
  const location = useLocation()
  const [ogImage, setOgImage] = useState(ogDefaultImgUrl)
  const [twitterImage, setTwitterImage] = useState(twitterDefaultImgUrl)

  const [url, setUrl] = useState(null)

  const updateUrl = () => {
    const { origin, pathname } = window.location
    setUrl(`${origin}${pathname}`)
  }

  const updateImages = () => {
    let _ogImage = ogDefaultImgUrl
    let _twitterImage = twitterDefaultImgUrl
    const currentRoute = compact(window.location.pathname.split('/'))

    if (currentRoute.length === 2 && currentRoute[0] === 'answer') {
      const url = getOgImage(currentRoute[1])
      _ogImage = url
      _twitterImage = url
    }

    setOgImage(_ogImage)
    setTwitterImage(_twitterImage)
  }

  useEffect(updateUrl, [location])
  useEffect(updateImages, [location])

  return (
    <Helmet>
      <meta property="og:url" content={url} />
      <meta property="og:image" content={`${ogImage}?cachebust=12345`} />
      <meta name="twitter:image" content={twitterImage} />
    </Helmet>
  )
}
