import React from 'react'
import { useRecoilValue } from 'recoil'
import { THEME_MAP } from '../../lib/constants'
import { clickedCellState, minimapInfoState, questionsState } from '../../state'
import { SocialShare } from './SocialShare'
import styles from './SelectedCell.module.css'
import { getBorough } from '../../lib/zip-utils'

export function SelectedCell() {
  const cell = useRecoilValue(clickedCellState)
  const questions = useRecoilValue(questionsState)
  const { svgWidth, svgHeight, width, height, x, y } = useRecoilValue(minimapInfoState)

  if (!cell) return null

  const padding = 3
  const panelWidth = 240
  const panelHeight = 240

  const alignLeft = { left: cell.x + cell.width + padding }
  const alignRight = { right: svgWidth - cell.x + padding }
  const alignTop = { top: cell.y }
  const alignBottom = {
    bottom: svgHeight - cell.y - cell.height,
  }

  const shouldAlignLeft = cell.x - x < width - panelWidth - (cell.width + padding)
  const shouldAlignTop = cell.y - y <= height / 2

  const xAlign = shouldAlignLeft ? alignLeft : alignRight
  const yAlign = shouldAlignTop ? alignTop : alignBottom

  const sliceAnswer = (answer, sliceIndex) => {
    return answer.length > sliceIndex ? `${answer.slice(0, sliceIndex)}...` : answer
  }

  // ugly fix, data from api should have questionId, not question
  const { questionThemeId, response, question: questionPrompt, zip_code } = cell.answer
  const currentQuestion = questions.find(({ prompt }) => prompt === questionPrompt)
  const prompt = questionPrompt ?? currentQuestion?.prompt
  const { fullTint, halfTint } = THEME_MAP[questionThemeId].color
  const borough = getBorough(zip_code)

  return (
    <>
      <div
        className={styles.cellBorder}
        style={{
          top: cell.y,
          left: cell.x,
          width: cell.width,
          height: cell.height,
        }}
      />
      <div
        className={styles.flippablePanel}
        style={{
          ...xAlign,
          ...yAlign,
          width: panelWidth,
          height: panelHeight,
        }}
      >
        <div className={styles.inner}>
          <div className={`${styles.side} ${styles.front}`} style={{ backgroundColor: fullTint }}>
            <div>
              <span className={styles.prompt}>{prompt}...</span>
              <br />
              <span className={styles.answer}>{sliceAnswer(response, 117)}</span>
            </div>
          </div>
          <div
            className={`${styles.side} ${styles.back}`}
            style={{ backgroundColor: fullTint, color: halfTint }}
          >
            <div>
              <div>
                <br />
                <span>Prompt from</span>
                <span className={styles.author}>
                  {currentQuestion?.author}, {currentQuestion?.role}
                </span>
              </div>
              <br />
              {borough && (
                <div>
                  <span>Answered by someone in</span>
                  <span className={styles.borough}>{borough}</span>
                </div>
              )}
            </div>
            <SocialShare fill={halfTint} />
          </div>
        </div>
      </div>
    </>
  )
}
