import React, { useRef, useState } from 'react'
import utilsStyles from '../../styles/utils.module.css'
import styles from './AboutPanel.module.css'
import { AboutContents } from './AboutContents'
import { Tabs } from './Tabs'

export const AboutPanel = ({ isVisible, setIsVisible }) => {
  const scrollableRef = useRef(null)
  const [activeTab, setActiveTab] = useState('About')

  const scrollToTop = () => {
    scrollableRef.current.scrollTop = 0
  }

  const close = (e) => {
    e.preventDefault()
    setIsVisible(false)
    scrollToTop()
  }

  const updateActiveTab = (tab) => {
    scrollToTop()
    setActiveTab(tab)
  }

  return (
    <div className={`${styles.panelWrapper} ${isVisible ? styles.panelIsVisible : ''}`}>
      <button className={styles.close} onClick={close} aria-label="Close the about panel">
        <span className={utilsStyles.screenreaderOnly}>Close the about panel</span>
      </button>
      <div className={styles.panel}>
        <Tabs activeTab={activeTab} setActiveTab={updateActiveTab} />

        <div className={styles.contentsWrapper} ref={scrollableRef}>
          <div className={styles.contents}>{AboutContents[activeTab.toLowerCase()]}</div>
        </div>
      </div>
    </div>
  )
}
