import React, { useEffect, useMemo, useRef, useState } from 'react'
import { usePromptRandomQuestion, useUpdateClickedCell } from '../../state'
import { EMPTY_CELL_BORDER_COLOR, THEME_MAP } from '../../lib/constants'
import { noisyGrid } from '../../lib/noisyGrid'
import { useMinimapUpdate } from '../Shared/useMinimapUpdate'
import { useGrid } from '../Shared/useGrid'
import { SelectedCell } from '../Shared/SelectedCell'
import styles from '../Shared/Layout.module.css'

export const PerlinNoiseLayout = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef()
  const { grid, width, height, rows, cols, cellPadding, cellSide, cellWidth } = useGrid()
  const setClicked = useUpdateClickedCell()
  const promptRandomQuestion = usePromptRandomQuestion()
  useMinimapUpdate(ref, width, height)

  const answersToDisplay = Math.min(data.length, cols * rows * 0.8)
  const noiseMemo = useMemo(() => noisyGrid(cols, rows, answersToDisplay), [
    cols,
    rows,
    answersToDisplay,
  ])

  const noiseGrid = noiseMemo.map((cell, i) => ({
    x: cell.x * cellWidth + cellPadding,
    y: cell.y * cellWidth + cellPadding,
    width: cellSide,
    height: cellSide,
    answer: data[i],
  }))

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <div className={`${styles.wrapper} ${!isVisible ? styles.wrapperIsHidden : ''}`} ref={ref}>
      <div style={{ width, height, position: 'relative' }}>
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          className={styles.svg}
        >
          {grid.map((cell, i) => (
            <rect
              key={i}
              {...cell}
              rx="4"
              stroke={EMPTY_CELL_BORDER_COLOR}
              fill="transparent"
              strokeWidth="1"
              onClick={promptRandomQuestion}
            />
          ))}

          {noiseGrid.map((cell, i) => (
            <rect
              key={i}
              {...cell}
              fill={
                cell.answer
                  ? THEME_MAP[cell.answer.questionThemeId].color.fullTint
                  : EMPTY_CELL_BORDER_COLOR
              }
              rx="4"
              onClick={() => setClicked(cell)}
            />
          ))}
        </svg>
        <SelectedCell />
      </div>
    </div>
  )
}
